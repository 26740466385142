import { $post } from '@/api/http.js'
import userModel from '@/api/user.js'
import tool from '@/util/tool.js'
import store from '../store'
import router from '../router'

class Login{
    /**
     * 获取登录相关配置信息
     * @returns 
     */
    getLoginSet(){
        return new Promise(resolve=>{
            $post("getBa",{},true,1).then(res=>{
                resolve(res.data)
            })
        })
    }

    /**
     * 账号登录
     * @param {string} mobile 登录账号
     * @param {string} password 登录密码
     * @returns 
     */
    accountLogin(mobile,password){
        if( !mobile ){
            tool.message("请输入登录账号~",'warning');
            return;
        }
        if( !password){
            tool.message("请输入登录密码",'warning');
            return
        }

        $post("login",{mobile,password},true,1).then(res=>{
            tool.message("登录成功")
            // 保存用户登录信息
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userId', res.data.user_id);
            localStorage.setItem('loginname', mobile);
            store.dispatch('setToken', res.data.token);
            store.dispatch('setUserId', res.data.user_id);
            store.dispatch('setUsername', mobile);

            //判断当前用户是不是多商户
            if( res.data.is_shop == 0 ){        //平台管理员
                console.log('toe',localStorage.getItem("token"));
                this.loginSuccess()
            }else{
                tool.message("您登录的账号不是管理员，请切换其他账号试试~")
            }
        }).catch(()=>{})
    }

    /**
     * 云端登录
     * @param {string} code 云端获取的code
     */
    kdCloudLogin(code){
        $post("getCloudEntry",{code},true,1).then(res=>{
            if( res.data.token && res.errorCode == 0 ){
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('userId', res.data.user_id);
                store.dispatch('setUsername', res.data.user_name);
                store.dispatch('setToken', res.data.token);
                store.dispatch('setUserId', res.data.user_id);

                //判断当前用户是不是多商户
                if( res.data.is_shop == 0 ){        //平台管理员
                    this.loginSuccess()
                }else{
                    tool.message("您登录的账号不是管理员，请切换其他账号试试~")
                }
            }else{
                tool.message(res.message,"error")
            }
        })
    }

    
    /**
     * 登录成功获取登录用户信息并跳转
     */
    loginSuccess(){
        userModel.getUserDetail().then(result=>{
            localStorage.setItem('kdFarmUserInfo', JSON.stringify(result));
            store.dispatch('setUserInfo', result);
            if( result.rank == 1){
                router.push('/admin/index')    
            }else{
                router.push('/shop/index')
            }
        })
    }
    /**
     * 获取极验证的验证数据
     * @returns 
     */
    getGeeTestVerify(){
        return new Promise(resolve=>{
            $post("getGeeTestVerify",{},true,1).then(res=>{
                resolve(res.data)
            })
        })
    }

    
    /**
     * 发送短信验证码
     * @param {string} mobile 接收验证码手机号
     * @param {number} type   类型 1发送验证码
     * @param {object} verify 极验证数据
     * @returns 
     */
    sendShortMsgCode(mobile,type,verify=null){
        return new Promise(resolve=>{
            if( !mobile ){
                tool.message("请先输入手机号");
                return
            }
            let param = { mobile,type }
            if(verify) param.verify = verify
            $post("sendMobileCode",param,true,1).then(res=>{
                tool.message("验证码已发送");
                resolve(res.data.verify)
            })
        })
    }
    /**
     * 管理员注册
     * @param {object} data 
     * @returns 
     */
    accountRegister(data){
        $post("register",data,true,1).then(res=>{
            tool.message("注册成功")
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userId', res.data.user_id);
            localStorage.setItem('loginname', data.mobile);

            store.dispatch('setToken', res.data.token);
            store.dispatch('setUserId', res.data.user_id);
            store.dispatch('setUsername', data.mobile);
            this.loginSuccess();
        })
    }

    logout(tips=true){
        const exitInfo = ()=>{
            store.dispatch("setToken",null)
            store.dispatch("setUserId",null)
            store.dispatch("setUsername",null)
            store.dispatch('setPermissionList',null)
            localStorage.setItem('token','')
            localStorage.setItem('userId','')
            localStorage.setItem('loginname','')
            if( localStorage.getItem('token') == false ){
                tool.message('已退出登录');
                router.push('/login')
            }
        }

        if( tips ){
            tool.confirm("确认退出登录吗？").then(()=>{
                // store.dispatch("setToken",null)
                // store.dispatch("setUserId",null)
                // store.dispatch("setUsername",null)
                // store.dispatch('setPermissionList',null)
                // localStorage.setItem('token','')
                // localStorage.setItem('userId','')
                // localStorage.setItem('loginname','')
                // if( localStorage.getItem('token') == false ){
                //     tool.message('已退出登录');
                //     router.push('/login')
                // }
                exitInfo()
            }).catch(()=>{})

            return
        }
        exitInfo()
    }

    /**
     * 多商户登录
     * @param {object} param 
     */
    shopLogin(param){
        if( !tool.returnMessage(param.mobile,'请输入登陆账号')) return
        if( !tool.returnMessage(param.password,'请输入登陆密码')) return
       
        $post('shopLogin',param ).then(res=>{
            tool.message("登录成功")

            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userId', res.data.user_id);
            localStorage.setItem('loginname', mobile);
            localStorage.setItem('program_id',res.data.program_id)

            store.dispatch('setToken', res.data.token);
            store.dispatch('setUserId', res.data.user_id);
            store.dispatch('setUsername', mobile);

            userModel.getUserDetail().then(result=>{
                localStorage.setItem('kdFarmUserInfo', JSON.stringify(result));
                store.dispatch('setUserInfo', result);
                $post('innerProgram',{ program_id:res.data.program_id },true,1).then(()=>{
                    router.push('/storeapp')
                })
            })
        },()=>{})
    }

    /**
     * 商户验证码登录
     * @param {object} param 
     */
    shopLoginByCode(param){
        if( !tool.returnMessage(param.mobile,'请输入登陆账号')) return
        if( !tool.returnMessage(param.code,'请输入验证码')) return
       
        $post('shopMobileLogin',param ).then(res=>{
            tool.message("登录成功")

            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userId', res.data.user_id);
            localStorage.setItem('loginname', mobile);
            localStorage.setItem('program_id',res.data.program_id)

            store.dispatch('setToken', res.data.token);
            store.dispatch('setUserId', res.data.user_id);
            store.dispatch('setUsername', mobile);

            userModel.getUserDetail().then(result=>{
                localStorage.setItem('kdFarmUserInfo', JSON.stringify(result));
                store.dispatch('setUserInfo', result);
                $post('innerProgram',{ program_id:res.data.program_id },true,1).then(()=>{
                    router.push('/storeapp')
                })
            })
        },()=>{})
    }

    getShopLoginQrcode(cb){
        $post('applyLoginUuid').then(res=>cb(res.data))
    }

    shopLoginCheck(uuid,cb){
        $post('checkLoginUuid',{uuid}).then(res=>{
            let d = res.data
            if( d.status == 2 ){  //已扫码
                tool.message("登录成功")
                cb(d.status)
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('userId', res.data.user_id);
                localStorage.setItem('loginname', res.data.mobile);
                localStorage.setItem('program_id',res.data.program_id)

                store.dispatch('setToken', res.data.token);
                store.dispatch('setUserId', res.data.user_id);
                store.dispatch('setUsername', res.data.mobile);

                userModel.getUserDetail().then(result=>{
                    localStorage.setItem('kdFarmUserInfo', JSON.stringify(result));
                    store.dispatch('setUserInfo', result);
                    $post('innerProgram',{ program_id:res.data.program_id },true,1).then(()=>{
                        router.push('/storeapp')
                    })
                })

                // cb(d.status)
            }
            
            if( d.status == 1 ){
                cb(d.status)
            }
            
            if( d.status == -1){
                cb(d.status)
            }
        })
    }
}

const loginModel  = new Login()
export default loginModel